

.WorkExperience{
    min-height: 100vh;
    padding-top: 4.5rem;
    //height: 100vh;
    //margin: 0 auto;
    padding-bottom: 2rem;
    text-align: center;
    color: white;
    background-color: black;
    p{
        margin-bottom: 0;
    }
    h1{
        margin-bottom: 2rem;
    }
    a{
        color: white;
    }
    .list{
        margin-top: 1rem;
        text-align: left;
        li{
            margin-bottom: 1rem;
        }
    }
    .description{
        color: white;
        background-color: rgb(52,58,64);
        // background-color: rgb(10,25,47);
        padding-top: 1rem;
    }
}

@media only screen and (max-width: 767px) {
    .description {
        margin-top: 1rem;
    }
}