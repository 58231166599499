/* Landing Page */
.landing {
  position: relative;
  background: url("../../../img/showcase.gif") no-repeat center center/cover;
  height: 100vh;
}

.landing-inner {
  color: #fff;
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.lead {
  font-size: 1rem;
}

.x-large {
  font-size: 3rem;
}

.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.no-padding {
  padding: 0;
}

.buttons {
  width: 110%;
  margin-left: -12px;
}

.social-links {
  margin-top: 1rem;
  cursor: pointer;
  font-size: 2rem;
}
.social-links a {
  color: inherit;
}
.social-links .social-link {
  margin-right: 1rem;
  position: relative;
}

.land-animation {
  position: relative;
  padding: 2rem;
  -webkit-animation: fadeOut 2s linear;
          animation: fadeOut 2s linear;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 0;
    left: -100%;
  }
  30% {
    left: 0;
    opacity: 0.3;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 0;
    left: -100%;
  }
  30% {
    left: 0;
    opacity: 0.3;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@media only screen and (min-width: 400px) {
  .buttons {
    width: 100%;
    margin-left: 0;
  }
}
@media only screen and (max-width: 364px) {
  .btn {
    margin-top: 1rem;
  }
}
@media only screen and (min-width: 514px) {
  .land-button {
    font-size: 1.25rem;
    padding: 0.5rem 1rem;
    line-height: 1.5;
    border-radius: 0.3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lead {
    margin-top: 1rem;
  }
  .buttons {
    margin-top: 1rem;
  }
  .social-links {
    margin-top: 1rem;
  }
}/*# sourceMappingURL=Landing.css.map */