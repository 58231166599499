.Footer{
    background-color: rgb(10,25,47) !important;
    color: white;
    text-align: center;
    padding: 1rem;
    text-transform: capitalize;
    .footer-paragraph{
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 767px) {
    .footer-paragraph {
        font-size: 10px;
    }
}