.About-me {
  padding-top: 4.5rem;
  min-height: 100vh;
  padding-bottom: 1rem;
  background-color: black;
  color: white;
}
.About-me h1 {
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 1rem;
}
.About-me .tab-description {
  margin-top: 1rem;
}
.About-me a {
  color: white;
}/*# sourceMappingURL=AboutMe.css.map */