.Education {
  padding-top: 4.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
  min-height: 100vh;
  .card-header{
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: left;
  }
  h2{
    font-size: 30px;
    
  }
  background-color: black;
  @media only screen and (min-width: 400px){
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .accordion{
      margin-top: 1rem;
  }
  h1 {
    text-align: center;
    color: white;
    margin-bottom: 2rem;
  }
  .secondTitle{
    margin-top: 2rem;
  }
  .title{
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: left;
  }

  .description{
    font-weight: 500;
    margin-top: 1rem;
  }
  .name {
    text-decoration: none;
    color: black;
    // color: rgb(65, 65, 65);
    //text-align: center;
    // float: left;
    margin-right: 4px;
    text-transform: capitalize;
  }

  .faCaretDown{
     // position: relative;
      // top: 2px;
      font-size: 2rem;  
      
  }

  .accordion-button {
    text-decoration: none;
    color: black;
    padding-left: 0;
  }

  .accordion-button:hover {
    color: black !important;
    text-decoration: underline;
  }
  .label-primary {
    background-color: #337ab7;
  }
  .label {
    display: inline;
    padding: 0.2em 0.6em 0.3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
  }
}
