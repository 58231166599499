.WorkExperience {
  min-height: 100vh;
  padding-top: 4.5rem;
  padding-bottom: 2rem;
  text-align: center;
  color: white;
  background-color: black;
}
.WorkExperience p {
  margin-bottom: 0;
}
.WorkExperience h1 {
  margin-bottom: 2rem;
}
.WorkExperience a {
  color: white;
}
.WorkExperience .list {
  margin-top: 1rem;
  text-align: left;
}
.WorkExperience .list li {
  margin-bottom: 1rem;
}
.WorkExperience .description {
  color: white;
  background-color: rgb(52, 58, 64);
  padding-top: 1rem;
}

@media only screen and (max-width: 767px) {
  .description {
    margin-top: 1rem;
  }
}/*# sourceMappingURL=WorkExperience.css.map */