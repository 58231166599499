.About-me{
  padding-top: 4.5rem;
  min-height: 100vh;
  padding-bottom: 1rem;
  background-color: black;
  color: white;
  h1{
      text-align: center;
      text-transform: capitalize;
      margin-bottom: 1rem;
  }
  .tab-description{
      margin-top: 1rem;
  }
  a{
      color: white;
  }
}